<template>
  <div class="w-100">
    <div class="backButton" @click="$router.back()">
      <i class="fas fa-chevron-left"/>
      All stores
    </div>
    <div class="productsWrap">
      <div v-for="item in colections" :key="item.label" class="title-wrap">
        <h2 class="default-title">
          {{ item.label }}
        </h2>
      </div>
      <ul 
        v-if="isGridLayout"
        class="store-item"
      >
        <li v-for="item in allCatsColection" :key="item.id">
          <carousel-item :item="item" :item-type="'circle'" class="store-item--point"/>
        </li>
      </ul>

      <stores-category-list-section
        v-if="isListLayout"
        class="category-list"
        :items="allCatsColection"
      />
      <!-- <observer :options="{ rootMargin: '100px' } " @intersect="onIntersect"/> -->
      <!-- <button
        v-if="allCatsColection.length > totalCount"
        class="addItem"
        @click="addNewMoreItems"
      >
        See more
      </button> -->
    </div>
  </div>
</template>
<script>
  import CarouselItem from 'src/components/swiper-carousel/carousel-item/carousel-item.vue';
  import { mapGetters, mapActions, mapState } from 'vuex';
  import StorefrontHeader from './storefrontHeader';
  import StoresCategoryListSection from './storesCategoryListSection';
  // import Observer from '../Observer.vue';
  export default {
    name: 'AllStoreItems',
    components: {
      CarouselItem,
      StorefrontHeader,
      StoresCategoryListSection,
      // Observer,
    },
    data() {
      return {
        total: 20,
      }
    },
    computed: {
      ...mapGetters({
        colections: 'allStoreCategories/colections',
        // catsColection: 'allStoreCategories/catsColection',
        allCatsColection: 'allStoreCategories/allCatsColection',
        layoutType: 'allStoreCategories/layoutType',
      }),
      ...mapState({
        totalCount: (state) => state.allStoreCategories.totalCount,
      }),
      // catsColection() {
      //   return this.allCatsColection.slice(0, this.total);
      // },
      isGridLayout() {
        return this.layoutType === 'grid';
      },
      isListLayout() {
        return this.layoutType === 'list';
      },
    },
    methods: {
      ...mapActions({
        getAllStore: 'allStoreCategories/getAllStore',
      }),
      // addNewMoreItems() {
      //   this.$store.commit(
      //     'allStoreCategories/setCatsColection',
      //     this.totalCount * 2,
      //   );
      // },
      // onIntersect() {
      //   if (this.allCatsColection.length > this.total) {
      //     this.total = this.total * 2;
      //   }
      // },
    },
  };
</script>
<style lang="scss">
.store-item {
  .store-item--point {
    .carousel-item-wrapper {
      padding: 16px 20px 22px;
    }
  }
}
</style>
<style lang="scss" scoped>
.productsWrap {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-right: 20px;

  .category-list {
    margin-left: 20px;
  }
}

.store-item {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  column-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  margin-bottom: 30px;
  margin-top: 16px;

  li {
    list-style-type: none;
    // margin-left: 20px;
    // margin-bottom: 50px;
    // width: 167px;
  }
  &--point {
    padding: 0;
    min-width: 135px;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default-title {
  font-weight: 600;
  font-size: 22px;
  margin-left: 20px;
  color: $main-color-items;
}

.addItem {
  padding: 13px 35px;
  background-color: $main-color;
  color: #fff;
  align-self: flex-end;
  outline: none;
  font-weight: 600;
  margin-top: 25px;
}

.backButton {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: -30px;
    font-weight: 600;
    font-size: 22px;
    color: $main-color-items;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        color: #5090AA;
      }
    }

    span {
      margin-left: 15px;
    }
  }
}
</style>
