<template>
  <div
    class="custom-select-wrap"
    :style="selectStyleObject"
  >
    <div 
      ref="select"
      class="select-input"
      tabindex="3"
      @click="toggleDropdown"
      @blur="onBlurSelect"
    >
      <div class="select">
        {{ !isMobile ? selectedText : 'Sort By' }}
      </div>
      
      <button 
        class="arrow-wrap"
      >
        <div 
          class="arrow"
          :class="{'arrow--opened': isSelectOpen}"
        />
      </button>
    </div>

    <ul 
      ref="dropdown"
      class="items"
      :class="{'items--active': isSelectOpen}"
      :style="dropDownStyleObject"
      @click="onSelectClick($event)"
    >
      <li
        v-for="option in options"
        :key="option.value"
        class="item"
      >
        <label :class="{'active': selectedText === option.text}">
          {{ option.text }}

          <input
            class="item-input"
            type="radio"
            :value="option.value"
          >

          <div class="active-check"/>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'ShareableCustomSelect',
    props: {
      width: {
        type: Number,
        default: 0,
        required: false,
      },
      height: {
        type: Number,
        default: 0,
        required: false,
      },
      bgColor: {
        type: String,
        default: '',
        required: false,
      },
      borderColor: {
        type: String,
        default: '',
        required: false,
      },
      optionsProps: {
        type: Array,
        default: null,
        required: false,
      },
      selectedText: {
        type: String,
        default: function() {
          return this.optionsProps[0].text
        },
        required: false,
      },
    },
    data() {
      return {
        isSelectOpenForBlur: true,
        isSelectOpen: false,
        options: this.optionsProps,
      };
    },
    computed: {
      selectStyleObject() {
        return {
          width: `${this.width || 110}px`,
          height: `${this.height || 32}px`, 
          backgroundColor: `${this.isMobile ? 'white' : this.bgColor || 'white'}`,  
          borderBottom: `1px solid ${this.borderColor || 'black'}`,
        };
      },
      dropDownStyleObject() {
        return {
          width: `${this.width || 110}px`,
        };
      },
      isMobile() {
        return screen.width <= 640;
      },
    },
    methods: {
      onBlurSelect() {
        setTimeout(() => {
          this.isSelectOpen = false;
        }, 300);
      },
      toggleDropdown() {
        this.isSelectOpen = !this.isSelectOpen;

        this.$refs.select.focus();
      },
      onSelectClick(event) {
        if (event.target.type === 'radio') {
          for(let li of this.$refs.dropdown.children) {
            li.childNodes[0].classList.remove('active');
          };

          const elem = event.target;

          elem.parentNode.classList.add('active');

          this.isSelectOpen = false;

          this.$emit('onSelectHandler', elem.value);
        };
      },
    },
  }
</script>

<style lang="scss" scoped>
  .custom-select-wrap {
    .select-input {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      width: 220px;
      height: 32px;

      .arrow-wrap {
        position: relative;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border: none;
        outline: none;
        cursor: pointer;

        .arrow {
          position: absolute;
          top: 0px;
          left: 0px;
          content: '';
          width: 10px;
          height: 10px;
          background-color: #333953;
          transform-origin: 25% 75%;
          clip-path: polygon(0 0, 50% 50%, 100% 100%, 0% 100%);
          transform: rotateZ(-45deg);
          transition: all;
          transition-duration: 0.6s;
          transition-timing-function: cubic-bezier(0.46, 1.31, 0.82, 1.15);

          &--opened {
            transform: rotateZ(-225deg);
            transition: all;
            transition-duration: 0.6s;
            transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
      }

      .select {
        margin-left: 10px;
        font-size: 14px;
        color: #333953;
        font-weight: 400;
      }
    }

    .items {
      opacity: 0;
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      font-size: 14px;
      padding-top: 6px;
      padding-bottom: 10px;
      background-color: white;
      box-shadow: 0px 0px 3px 0px grey;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &--active {
        transition: all;
        transition-duration: 1s;
        opacity: 1;
      }

      .item {
        color: #333953;
        margin-bottom: 5px;

        &-input {
          visibility: hidden;
          position: absolute;
          width: 0px;
          height: 0px;
        }

        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          margin: 0px;
          width: 100%;
          cursor: pointer;

          &.active {
          color: #5090AA;
          font-weight: 600;

          .active-check {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 10px;
              left: -2px;
              height: 2px;
              width: 9px;
              -webkit-transform: rotateZ(-45deg);
              transform: rotateZ(236deg);
              background-color: #5090AA;
            }

            &::after {
              content: '';
              position: absolute;
              top: 7px;
              left: 1px;
              height: 2px;
              width: 17px;
              -webkit-transform: rotateZ(-45deg);
              transform: rotateZ(308deg);
              background-color: #5090AA;
            }
          }
        }

        }

        &:hover {
          cursor: pointer;
          background-color: #9a9a9a23;
        }

        span {
          padding-left: 10px;
        }
      }
    }
  }
</style>