<template>
  <div
    class="layout"
    @click="hideFilters($event)"
  >
    <div class="wrapper">
      <div
        class="containers"
        :class="{'containers__opened': isCategoryListOpen}"
      >
        <div
          class="title"
          @click="openCloseList('cats')"
        >
          <span>Categories</span>

          <button>
            <img
              :src="require('src/assets/icons/open-close-arrow.svg')"
              :class="{'list-is-open': isCategoryListOpen}"
            >
          </button>
        </div>
        <div class="all-stores-link">
          <router-link :to="storeLink()" @click.native="showAllStores({title:'All'})">
            All
          </router-link>

          <span>
            {{ `(${storeCount})` }}
          </span>

          <i
            v-show="!isAllStoresPage"
            class="fas fa-chevron-right"
          />
        </div>
        <ul
          v-for="store in allStore"
          :key="store.id"
          class="stores-list"
        >
          <li v-if="store.cats.length" class="stores-item">
            <router-link :to="storeLink(store.link)">
              {{ store.label }}
            </router-link>
            <i
              v-if="CollectionKey === store.link"
              class="fas fa-chevron-right"
            />
          </li>
        </ul>
      </div>

      <div class="list-divider"/>

      <div
        class="containers"
        :class="{'containers__opened': isAttributesListOpen}"
      >
        <div
          class="title"
          @click="openCloseList('attrs')"
        >
          <span>Attributes</span>

          <span
            v-if="selected.length"
            class="attributes-amount"
          >
            {{ `(${selected.length} applied)` }}
          </span>

          <button>
            <img
              :class="{'list-is-open': isAttributesListOpen}"
              :src="require('src/assets/icons/open-close-arrow.svg')"
            >
          </button>
        </div>
        <ul
          class="all-attributes"
        >
          <li
            v-for="badge in badges"
            :key="badge.id"
          >
            <label>
              <input
                type="checkbox"
                :value="badge.id"
                :checked="selected.includes(badge.id)"
                @change="checkboxHandler($event)"
              >

              <div class="checkbox"/>

              {{ badge.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

  import badgesService from 'src/service/badges-service';
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'LeftSidebarMenu',
    data() {
      return {
        isCategoryListOpen: false,
        isAttributesListOpen: false,
        selected: this.$route.query.filters ? this.$route.query.filters.replace('badges__in:', '').split('|') : [],
        badges: null,
      };
    },
    computed: {
      ...mapGetters({
        allStore: 'allStoreCategories/allStore',
        storeCount: 'allStoreCategories/storeCount',
        CollectionKey: 'allStoreCategories/CollectionKey',
        head: 'allStoreCategories/head',
      }),
      storeLink() {
        const filterParams = this.$route.query.filters ? `&filters=${this.$route.query.filters}` : '';
        const sortParams = this.$route.query.sort ? `&sort=${this.$route.query.sort}` : '';
        return store => `${store ? `/stores/${store}?` : '/stores?'}${sortParams}${filterParams}`
      },
      isAllStoresPage() {
        return this.$route.fullPath.includes('/stores/');
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler(path) {
          if (path.path === '/stores') {
            this.$store.commit('allStoreCategories/setCollectionKey', '');
            this.$store.commit('allStoreCategories/setShowStore', true);

            if (!path.query?.filters) {
              this.selected = [];
            }
            return;
          }
          const item = path.params.category;
          this.itemStoreParams(item);
        },
      },
      selected: {
        handler(value) {
          const filters = value.length ? `badges__in:${value.join("|")}` : '';

          this.setFilters(value);
          this.getAllStore({
            ...this.$route.query,
            filters,
          });

          if (value.length) {
            this.$router.push({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                filters: value.length ? filters : null,
              },
            })
          } else if (this.$route.query.filters){
            const query = Object.assign({}, this.$route.query);
            delete query.filters;
            this.$router.replace({ query });
          }
        },
      },
      CollectionKey() {
        this.updateTitle();
      },
    },
    async created() {
      this.badges = await badgesService.getBadgesNames()
    },
    mounted() {
      this.updateTitle();
    },
    destroyed() {
      this.$store.commit('allStoreCategories/setCollectionKey', 'All');
      this.$store.commit('allStoreCategories/setShowStore', true);
    },
    methods: {
      ...mapMutations({
        setFilters: 'allStoreCategories/setFilter',
      }),
      ...mapActions({
        getAllStore: 'allStoreCategories/getAllStore',
      }),
      showAllStores(title) {
        this.$store.commit('allStoreCategories/setCollectionKey', title);
        this.$store.commit('allStoreCategories/setShowStore', true);
      },
      itemStoreParams(params) {
        this.$store.commit('allStoreCategories/setCollectionKey', params);
        this.$store.commit('allStoreCategories/setShowStore', false);
      },
      openCloseList(value) {
        switch (value) {
          case 'cats':
            this.isCategoryListOpen = !this.isCategoryListOpen;

            return;
          case 'attrs':
            this.isAttributesListOpen = !this.isAttributesListOpen;

            return;
        };
      },
      hideFilters(e) {
        if (
          e.target.classList.value.includes('layout')
          ||
          e.target.classList.value.includes('router-link-active')
        ) {
          this.$emit('hideFilters');
        };
      },
      checkboxHandler(e) {
        const value = e.target.value;

        if (e.target.checked) {
          const selectedCopy = [...this.selected];

          selectedCopy.push(value);

          this.selected = selectedCopy;
        } else {
          this.selected = this.selected.filter(id => id !== value);
        };
      },
      updateTitle() {
        const link = this.CollectionKey;
        const store = this.allStore.find(store => store.link === link);
        const newTitle = store ? store.label : this.head.title;
        this.$bus.$emit('updateTitle', newTitle);
      },
    },
  };
</script>
<style lang="scss" scoped>
$md-breakpoint: 1200px;
.wrapper {
  width: 349px;
  height: 100%;
  background-color: $leftSideBar-background-color;
  margin: 20px;
  padding-top: 25px;

  .divider {
    width: calc(100% - 30px);
    height: 1px;
    background-color: $main-input-border-color;
    margin-bottom: 30px;
  }
}
.containers {
  width: 349px;
  padding: 10px 5px 35px 35px;
  height: 0px;
  overflow-y: hidden;
  transition: all;
  transition-duration: 1.5s;

  .stores-list {
    padding: 0px;
  }

  &__opened {
    height: unset;
    transition: all;
    transition-duration: 1.5s;
  }
}

.list-divider {
  position: relative;
  left: 18px;
  width: 90%;
  height: 2px;
  background-color: #D9E0EE;
}

.title {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-weight: 600;
    font-size: 18px;
    color: $main-color-items;
  }

  .attributes-amount {
    font-weight: 500;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    flex: 1;
  }

  button {
    margin-right: 20px;
    border: none;
    outline: none;

    img {
      transform: rotateZ(180deg);
      transition: all;
      transition-duration: 0.8s;

      &.list-is-open {
        transform: rotateZ(720deg);
        transition: all;
        transition-duration: 0.8s
      }
    }
  }
}

.all-stores-link {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;

  span {
    margin-right: 20px;
    color: $main-color;
  }

  i {
    color: $main-color;
    margin-right: 22px;
  }

  a {
    flex-grow: 1;
    font-size: 15px;
    cursor: pointer;
  }
}

.stores-item {
  list-style-type: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    color: $main-color;
    margin-right: 30px;
  }
}

a:hover {
  color: $main-color;
}

a {
  color: $main-color-items;
}

.router-link-exact-active {
  color: $main-color;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

.all-attributes {
  list-style-type: none;
  padding: 0px;

  li {
    label{
      display: inline-flex;
      align-items: center;
      margin-bottom: 4px;

      &:hover {
        cursor: pointer;
      }

      input {
        visibility: hidden;
        width: 0px;
        height: 0px;

        &:checked + .checkbox {
          background-size: cover;
          background-image: url(../../assets/icons/attrs-select-icon.svg);
          border: none;
        }
      }

      .checkbox {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: white;
        margin-right: 12px;
        border: 1px solid #7F8095;
      }
    }
  }
}

@media (max-width: $md-breakpoint) {
  .layout {
    position: absolute;
    height: 100%;
    width: 100vw;
    z-index: 2;
    top: 0px;
    left: -100%;

    .wrapper {
      margin: 0px;
      transition: all;
      transition-duration: 1s;
    }
  }
}
</style>

