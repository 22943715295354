<template>
  <div class="storefront-header">
    <h1>Storefront</h1>
    <div class="select-wrap">
      <span class="select-title">
        Sort by:
      </span>

      <button
        class="filter-button"
        @click="showFilters()"
      >
        <img :src="require('src/assets/icons/filter-btn-icon.svg')" alt="filter">

        Filter
      </button>

      <shareable-custom-select
        :width="220"
        :bg-color="'#EEF0F4'"
        :border-color="'#979797'"
        :options-props="selectOptionsArray"
        :selected-text="selectedText"
        @onSelectHandler="onSelectHandler"
      />
    </div>

    <div 
      class="button-wrap"
      @click="buttonClickHandler($event)"
    >
      <button
        class="grid"
        :class="{'grid--active': isGridActive}"
      />
      <button
        class="list"
        :class="{'list--active': !isGridActive}"
      />
    </div>
  </div>
</template>

<script>
  import ShareableCustomSelect from 'src/components/shareable-custom-select/shareable-custom-select.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'StorefrontHeader',
    components: {
      ShareableCustomSelect,
    },
    data() {
      return {
        isGridActive: true,
        selectOptionsArray: [
          {
            text: 'Followers',
            value: 'followers',
          },
          {
            text: 'A to Z',
            value: 'title',
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        getSort: 'allStoreCategories/sort',
        layoutType: 'allStoreCategories/layoutType',
      }),
      selectedText() {
        return this.selectOptionsArray.find(option => option.value === this.$route.query.sort)?.text;
      },
    },
    created() {
      if (this.layoutType === 'grid') {
        this.isGridActive = true;

        return;
      };

      this.isGridActive = false;
    },
    methods: {
      ...mapActions({
        setLayoutTypeAction: 'allStoreCategories/setLayoutTypeAction',
        getAllStore: 'allStoreCategories/getAllStore',
      }),
      ...mapMutations({
        setSort: 'allStoreCategories/setSort',
      }),
      buttonClickHandler(event) {
        const targetName = event.target.className;

        if (targetName.includes('grid')) {
          this.isGridActive = true;

          this.setLayoutTypeAction('grid');
        };

        if (targetName.includes('list')) {
          this.isGridActive = false;

          this.setLayoutTypeAction('list');
        };
      },
      onSelectHandler(value) {
        if (value !== this.$route.query.sort) {
          this.setSort(value);
          this.getAllStore({
            ...this.$route.query,
            sort: value,
          });

          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              sort: value,
            },
          })
        }
      },
      showFilters() {
        this.$emit('showFilters');
      },
    },
  }
</script>

<style lang="scss" scoped>
  .storefront-header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 35px 0 20px;

    h1{
      font-size: 32px;
      font-weight: 600;
      flex-grow: 2;
      margin: 0px;
    }

    .select-wrap {
      display: flex;
      align-items: center;  
      margin-right: 25px;

      .filter-button {
        display: none;
        align-items: center;
        width: 85px;
        height: 32px;
        border-radius: 5px;
        border: 1px solid #5090AA;
        color: #5090AA;
        font-size: 14px;
        font-weight: 500;
        outline: none;
        background-color: white;
        padding-left: 15px;

        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }

      span {
        margin-right: 5px;
        color: var(--txt-dark-grey);
      }
    }

    .button-wrap {
      display: flex;

      button {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        border: none;
        outline: none;

        &.grid {
          background-image: url('../../assets/icons/store-list-active-bg.svg');

          &--active {
            background-image: url('../../assets/icons/store-grid-active-bg.svg');
            background-position: unset;
            box-shadow: 0px 0px 4px 0px #5090aa;
          }
        }

        &.list {
          background-image: url('../../assets/icons/store-grid-active-bg.svg');
          background-position: 100%;

          &--active {
            background-image: url('../../assets/icons/store-list-active-bg.svg');
            box-shadow: 0px 0px 4px 0px #5090aa;
          }
        }

        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }

  @media(max-width: 640px) {
    .storefront-header {
      flex-wrap: wrap;
      position: relative;
      width: 100vw;
      padding: 0px;

      h1 {
        margin-left: 20px;
      }

      .select-wrap {
        order: 3;
        flex-basis: 100%;
        margin-right: 0px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: #EEF0F4;

        .filter-button {
          display: flex;
        }

        .select-title {
          display: none;
        }
      }

      .button-wrap {
        justify-content: flex-end;
        margin-right: 20px;
      }
    }
  }
</style>